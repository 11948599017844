import logo from '../img/ilab_logo.svg'; // Tell webpack this JS file uses this image
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import FooterInfo from "./FooterInfo";

const Footer = () => {

	const {t} = useTranslation();
	const dispatch = useDispatch();

	const openContacts = () => {
		dispatch({type: "OPEN_MODAL", modalLevel : 1, modalType: 'contacts', modalIsOpen: true})
	}

	return (
		<>
			<div className="i_bottom">
				<div className="i_bottom_left">
					<div>
						{t('I_DOC')} <a
						href="https://docs.apicore.kz/distributor-api/"
						target="_blank">Distributor API</a>
					</div>
					<div>
						{t('I_DOC')} <a
						href="https://docs.apicore.kz/dealer-api/"
						target="_blank">Dealer API</a>
					</div>
					<div className="i_bottom_left-item">
						<div className="i_social">
							<Link to="https://www.instagram.com/apicore.kz/" className="i_instagram"
							      target="_blank"></Link>
							<Link to="https://www.facebook.com/apicore.kz/" className="i_facebook"
							      target="_blank"></Link>
						</div>
					</div>


				</div>
				<div className="i_bottom_center">
					<nav>
						<Link to="/payment">{t('I_TERMS_OF_PAYMENT')}</Link>
						<Link to="/privacy">{t('I_PRIVACY_POLICY')}</Link>
						<Link to="/offerta">{t('I_OFFERTA')}</Link>
					</nav>
				</div>
				<div className="i_bottom_right">
					<FooterInfo />
					<div className={'i_bottom_contacts'} onClick={openContacts}>
						<span>{t('I_CONTACTS')}</span>
					</div>
					<div className="i_social">
						<Link to="https://www.instagram.com/apicore.kz/" className="i_instagram" target="_blank"></Link>
						<Link to="https://www.facebook.com/apicore.kz" className="i_facebook" target="_blank"></Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default Footer;